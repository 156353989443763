import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import Portal from "../portal/Portal";
import React, {useContext, useState} from "react";
import {ICoursesForAttach, ICoursesSearchForAttach} from "../../../../models/courses";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as CoursesIcon} from "../../../../assets/images/folders/courses-icon.svg";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import {IUser, IUserCourse, IUserWithoutEducationId} from "../../../../models/users";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../loader/Loader";
import ScrollContainer from "../../scroll-container/ScrollContainer";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {IGroup} from "../../../../models/groups";
import {IFolderMove, IGroupedFolder} from "../../../../models/folders";
import Select, {SingleValue} from "react-select";

interface IModalAttachCourseUser {
    onClose: () => void
    user?: IUser
    group?: IGroup
}

const ModalAttachCourseUser = (
    {
        onClose,
        user,
        group
    }: IModalAttachCourseUser
) => {

    const queryClient = useQueryClient()
    const {show, setShow} = useContext(LoaderContext)
    const [error, setError] = useState<number | null>(null)
    const [errorAttach, setErrorAttach] = useState<string>("")
    const [activeCourse, setActiveCourse] = useState<number>(0)
    const [listCourse, setListCourse] = useState<ICoursesForAttach[]>([])
    const [userWithoutEducationId, setUserWithoutEducationId] = useState<IUserWithoutEducationId[]>([])
    const [activeUserWithoutEducationId, setActiveUserWithoutEducationId] = useState<IUserWithoutEducationId>({} as IUserWithoutEducationId)

    const listCourses = useQuery(
        ["courses_attach"],
        () => UsersService.getListCoursesForAttach(),
        {
            onSuccess: (response) => {
                setListCourse(response)
            }
        }
    )

    const listUsersWithoutEducationId = useQuery(
        ["list_users_without_education_id"],
        () => UsersService.getListUsersWithoutEducationId(),
        {
            enabled: window.location.host.indexOf("fedkurs") !== -1,
            onSuccess: (response) => {
                setUserWithoutEducationId(response)
            },
            onError: (error) => {}
        }
    )

    const addCourse = useMutation(
        (data: IUserCourse) => UsersService.attachUserInCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user_courses", user?.id])
                setShow(false)
                onClose()
            }
        }
    )

    const searchCourse = useMutation(
        (data: ICoursesSearchForAttach) => UsersService.searchCourseForAttach(data),
        {
            onSuccess: (response) => {
                setListCourse(response)
            }
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        let data: ICoursesSearchForAttach = {
            name: e.target.value
        }
        searchCourse.mutate(data)
    }

    const handleAttach = () => {
        if(activeCourse !== 0) {
            if(user) {
                let data: IUserCourse = {
                    "courseId": activeCourse,
                    "userId": user.id
                }
                if(window.location.host.indexOf("fedkurs") !== -1) {
                    // if(Object.keys(activeUserWithoutEducationId).length > 0) {
                    //     data.userWithoutEducationId = activeUserWithoutEducationId
                    //     setShow(true)
                    //     addCourse.mutate(data)
                    // } else {
                    //     setErrorAttach("Выберите пользователя для привязки")
                    // }
                    setShow(true)
                    addCourse.mutate(data)
                } else {
                    setShow(true)
                    addCourse.mutate(data)
                }
            }
        } else {
            setErrorAttach("Выберите курс")
        }
    }

    const handleClick = (course: number) => {
        setActiveCourse(course)
        setErrorAttach("")
    }

    const handleChangeUser = (newValue: SingleValue<IUserWithoutEducationId>) => {
        if(newValue) {
            setActiveUserWithoutEducationId(newValue)
            setErrorAttach("")
        }
    }

    if(listCourses.isLoading) return <Loader/>
    if(listUsersWithoutEducationId.isLoading) return <Loader/>

    if(listCourses.isError) {
        const e = listCourses.error as AxiosError
        setError(e.response ? e.response.status : 400)
    }
    if(listUsersWithoutEducationId.isError) {
        const e = listUsersWithoutEducationId.error as AxiosError
        setError(e.response ? e.response.status : 400)
    }

    if(!listCourses.data) return <div className="text-red-600 text-center">Ошибка данных</div>

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <div className="flex flex-col gap-[18px] text-[16px]">
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Назначить курс
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="relative">
                        <SearchIcon className="absolute top-[12px] left-[10px]"/>
                        <input
                            type="text"
                            onChange={handleSearch}
                            placeholder="Введите название курса"
                            className="border-solid border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] w-full focus:outline-none focus:border-[#17AB57]"/>
                    </div>
                    <div className="flex flex-col gap-[15px]">
                        <div
                            className="rounded-[6px] border-solid border-[1px] border-[#BDBDBD] py-[8px] flex flex-col gap-[4px] h-[210px]">
                            <ScrollContainer chat={false}>
                                {listCourse.map((course, index) => (
                                    <div
                                        onClick={() => handleClick(course.id)}
                                        key={index}
                                        className={`cursor-pointer flex w-full items-center py-[5px] px-[10px] text-[16px] hover:underline font-medium` + (activeCourse === course.id ? " underline bg-[#17AB571a]" : "")}>
                                        <div className="flex gap-[6px] w-[50%] items-center">
                                            <CoursesIcon className="flex-shrink-0"/>
                                            {course.name} {window.location.host.indexOf("fedkurs") !== -1 && (<>({course.id})</>)}
                                        </div>
                                        <div className="w-[50%]">
                                            {course.path}
                                        </div>
                                    </div>
                                ))}
                            </ScrollContainer>
                        </div>
                        {window.location.host.indexOf("fedkurs") !== -1 && (
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#17AB57",
                                        primary25: "#17AB571a"
                                    }
                                })}
                                required={true}
                                placeholder={"Выберите человека"}
                                options={userWithoutEducationId}
                                onChange={handleChangeUser}
                                isSearchable={true}
                                noOptionsMessage={() => "Нет пользователей"}
                                getOptionLabel={(user: IUserWithoutEducationId) => "Сделка: " + user.dealId + ", Почта: " + user.email + ", ФИО: " + user.userName}
                                getOptionValue={(user: IUserWithoutEducationId) => String(user.id)}
                            />
                        )}
                        {errorAttach && (<p className="text-red-600">{errorAttach}</p>)}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        {group ? (
                            <Link to={`/attach-course-in-group/${activeCourse}/group/${group.id}`}
                                  className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]">
                                Назначить
                            </Link>
                        ) : (
                            <button onClick={handleAttach} className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]">
                                Назначить
                            </button>
                        )}
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>
                            Отмена
                        </button>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default ModalAttachCourseUser