import {
    IListItemLessonByCourse,
    IListLessonsByCourse
} from "../../../../../../../models/lessons";
import React, {useEffect, useRef, useState} from "react";
import CourseLessonEdit from "./course-lesson-edit/CourseLessonEdit";
import CourseLessonItem from "./course-lesson-item/CourseLessonItem";
import {useQuery} from "react-query";
import {LessonsService} from "../../../../../../../services/lessons.service";
import {useParams} from "react-router-dom";
import Loader from "../../../../../../ui/loader/Loader";
import {ReactComponent as SearchIcon} from "../../../../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../../../../assets/images/global/arrow-sort-icon.svg";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../../../../ui/scroll-container/ScrollContainer";

interface ICourseLessonList {
    activeTab: number
}

const CourseLessonList = (
    {
        activeTab
    }: ICourseLessonList
) => {
    const [filterLessons, setFilterLessons] = useState<IListItemLessonByCourse[]>([])
    const [lessonEdit, setLessonEdit] = useState<boolean>(false)
    const refListLessons = useRef<HTMLDivElement>(null)
    const [rect, setRect] = useState<DOMRect>({} as DOMRect)
    const [lessonId, setLessonId] = useState<number | undefined>(undefined)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [error, setError] = useState<number>(400)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")

    const {courseId} = useParams()

    const lessons = useQuery<IListLessonsByCourse>(
        ['list_lessons_course', Number(courseId)],
        () => LessonsService.getByCourse(Number(courseId)),
        {
            onSuccess: (response) => {
                setFilterLessons(response.lessons)
            }
        }
    )

    useEffect(() => {
        if (activeTab == 2 && refListLessons.current) {
            setRect(refListLessons.current.getBoundingClientRect())
        }
    }, [activeTab])

    const handleAddLesson = () => {
        setLessonId(undefined)
        setLessonEdit(true)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase())
        if (lessons.data) {
            setFilterLessons(lessons.data.lessons.filter(lesson => lesson.name.toLowerCase().includes(e.target.value.toLowerCase())))
            if (refListLessons.current) {
                setRect(refListLessons.current.getBoundingClientRect())
            }
        }
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: IListItemLessonByCourse, b: IListItemLessonByCourse) => a.name > b.name ? 1 : -1},
            {method: (a: IListItemLessonByCourse, b: IListItemLessonByCourse) => a.name < b.name ? 1 : -1}
        ]
        const sorted = filterLessons.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilterLessons([...sorted])
        setActiveSort(!activeSort)
    }

    const onDelete = (id: number) => {
        setFilterLessons(filterLessons.filter(f => f.id !== id))
    }

    const onScroll = () => {
        if (refListLessons.current) {
            setRect(refListLessons.current.getBoundingClientRect())
        }
    }

    if (lessons.isLoading) return <div className="text-center">Загрузка...</div>

    if (lessons.isError) {
        const e = lessons.error as AxiosError
        setError(e.response ? e.response.status : error)
    }

    if (!lessons.data) return <div className="text-center">Данных нет</div>

    return (
        <>
            {showLoader && <Loader/>}
            {lessons.isError && (<ErrorMessage numberError={error}/>)}
            {(filterLessons.length > 0 || lessons.data.lessons.length > 0) ? (
                <div
                    className={`flex gap-[12px] items-center w-full` + (activeTab == 2 ? " block" : " hidden") + (lessonEdit ? " hidden" : " block")}>
                    <div className="relative w-full">
                        <SearchIcon className="absolute top-[8px] left-[10px]"/>
                        <input
                            onChange={handleSearch}
                            type="text"
                            placeholder="Поиск"
                            className="border-solid w-full bg-[#F2F2F2] border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] focus:outline-none focus:border-[#17AB57]"/>
                    </div>
                    {/*{lessons.data.abilityEdit && (*/}
                        <div
                            onClick={handleAddLesson}
                            className="cursor-pointer px-[16px] py-[8px] rounded-[5px] bg-[#17AB57] text-white text-[16px] font-medium flex-shrink-0">
                            Добавить урок
                        </div>
                    {/*)}*/}
                </div>
            ) : (
                <div
                    className={`flex justify-between items-center mb-[8px]` + (activeTab == 2 ? " block" : " hidden") + (lessonEdit ? " hidden" : " block")}>
                    <div className="text-[24px] font-bold text-[#292B2C]">Список уроков</div>
                    <div
                        onClick={handleAddLesson}
                        className="cursor-pointer px-[16px] py-[8px] rounded-[5px] bg-[#17AB57] text-white text-[16px] font-medium flex-shrink-0">
                        Добавить урок
                    </div>
                </div>
            )}
            <div
                ref={refListLessons}
                className={`bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full ` + (activeTab == 2 ? " block" : " hidden")}>
                {lessonEdit ? (
                    // <ScrollContainer>
                        <div className="h-full">
                            <CourseLessonEdit
                                setLessonEdit={setLessonEdit}
                                lessonId={lessonId}
                            />
                        </div>
                    // </ScrollContainer>
                ) : (
                    <div className="h-full">
                        {(filterLessons.length > 0 || lessons.data.lessons.length > 0) ? (
                            <>
                                <div className="flex flex-col h-full">
                                    <div
                                        className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden">
                                        <div className="w-[25%] cursor-pointer flex gap-[4px]" onClick={handleSort}>
                                            Название
                                            <ArrowSortIcon
                                                className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                                        </div>
                                        <div className="w-[10%]">Тип файла</div>
                                        <div className="w-[10%]">Дата создания</div>
                                        <div className="w-[10%]">Дата изменения</div>
                                        <div className="w-[6%] flex justify-end">Действия</div>
                                    </div>
                                    <div className="h-full">
                                        <>
                                            {filterLessons.map((lesson, index) => (
                                                <CourseLessonItem
                                                    searchTerm={searchTerm}
                                                    key={index}
                                                    courseId={Number(courseId)}
                                                    onDelete={onDelete}
                                                    index={index}
                                                    lastIndex={(filterLessons.length - 1)}
                                                    setLessonEdit={setLessonEdit}
                                                    setLessonId={setLessonId}
                                                    parentParameter={rect}
                                                    lesson={lesson}/>
                                            ))}
                                        </>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center h-full">
                                <div className="flex flex-col gap-[18px] text-center">
                                    <div className="text-[24px] font-medium text-[#828282]">Уроков пока нет</div>
                                    <div
                                        onClick={handleAddLesson}
                                        className="text-white py-[8px] px-[16px] w-full bg-[#17AB57] cursor-pointer text-[16px] rounded-[5px]">
                                        Добавить урок
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default CourseLessonList