import axios from "axios";
import {
    IGetVisitsUser,
    IProgressCourseUser, IRecoveryPassword, IUpdateLessonPassMark,
    IUser, IUserCourse,
    IUserCourses, IUserCourseUntie,
    IUserDetail,
    IUserDetailId,
    IUserGroup, IUserLeft, IUserPassingTest,
    IUserPasswordRepeat, IUserResultTest,
    IUserSearchCourse, IUserWithoutEducationId, IVisitsUser
} from "../models/users";
import {IRole} from "../models/role";
import {ILogin, IToken, IUserAuth} from "../models/login";
import AuthHeader from "./auth-header";
import {IPassingTest} from "../models/tests";
import Cookies from "universal-cookie";
import {ICoursesForAttach, ICoursesSearchForAttach} from "../models/courses";
import {ILessonsAttachInUser} from "../models/lessons";
import {Constants} from "../constans/constants";

const cookies = new Cookies()

const token = cookies.get("token")
let headersAuthorization = ""
if (token) {
    headersAuthorization = "Bearer " + token
}

const config = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': headersAuthorization
    }
}

export const UsersService = {
    async getAll() {
        const response = await axios.get<IUser[]>(Constants.LOCATION + '/api/User', {headers: AuthHeader()})
        return response.data
    },

    async getAllForAttachGroup(id: number) {
        const response = await axios.get<IUser[]>(Constants.LOCATION + `/api/User/attachGroup/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async getRoles() {
        const response = await axios.get<IRole[]>(Constants.LOCATION + '/api/User/roles', {headers: AuthHeader()})
        return response.data
    },

    async getRole(id: number) {
        const response = await axios.get<IRole>(Constants.LOCATION + `/api/User/role/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async create(data: IUserDetail) {
        const response = await axios.post<IUser>(Constants.LOCATION + '/api/User/add', data, {headers: AuthHeader()})
        return response.data
    },

    async getById(id: string | undefined) {
        const response = await axios.get<IUser>(Constants.LOCATION + `/api/User/${id}`, {headers: AuthHeader()})
        return response.data
    },

    async update(id: number, data: IUserDetail) {
        const response = await axios.post<IUser>(Constants.LOCATION + `/api/User/update/${id}`, data, {headers: AuthHeader()})
        return response.data
    },

    async login(data: ILogin) {
        const response = await axios.post<IUserAuth>(Constants.LOCATION + `/api/User/login`, data, {headers: AuthHeader()})
        return response.data
    },

    async refreshToken(data: IToken) {
        const response = await axios.post<IUserAuth>(Constants.LOCATION + `/api/User/refreshToken`, data)
        return response.data
    },

    async searchCourse(search: string, courseId: number) {
        const dataQuery: IUserSearchCourse = {
            name: search,
            courseId: courseId
        }
        const {data} = await axios.post<IUser[]>(Constants.LOCATION + `/api/User/searchCourse`, dataQuery, {headers: AuthHeader()})
        return data
    },

    async updatePassword(dataPassword: IUserPasswordRepeat) {
        const {data} = await axios.post<IUser>(Constants.LOCATION + `/api/User/updatePassword`, dataPassword, {headers: AuthHeader()})
        return data
    },

    async updateSupervisor(id: number) {
        const {data} = await axios.post<IUser>(Constants.LOCATION + `/api/User/updateSupervisor/${id}`, {}, {headers: AuthHeader()})
        return data
    },

    async excludeGroup(id: number) {
        const {data} = await axios.post<IUser>(Constants.LOCATION + `/api/User/excludeGroup/${id}`, {}, {headers: AuthHeader()})
        return data
    },

    async delete(data: IUserDetailId) {
        return await axios.post<boolean>(Constants.LOCATION + `/api/User/delete/`, data, {headers: AuthHeader()})
    },

    async uploadAvatar(data: any) {
        const response = await axios.post<IUser>(Constants.LOCATION + `/api/File/uploadAvatar/`, data, config)
        return response.data
    },

    async getCourses(id: number) {
        const {data} = await axios.get<IUserCourses[]>(Constants.LOCATION + `/api/User/courses/${id}`, {headers: AuthHeader()})
        return data
    },

    async getListCoursesForAttach() {
        const {data} = await axios.get<ICoursesForAttach[]>(Constants.LOCATION + `/api/User/listCourses`, {headers: AuthHeader()})
        return data
    },

    async searchCourseForAttach(dataSubmit: ICoursesSearchForAttach) {
        const {data} = await axios.post<ICoursesForAttach[]>(Constants.LOCATION + `/api/User/searchCourseForAttach`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async updateGroup(dataUpdate: IUserGroup) {
        const {data} = await axios.post<IUser>(Constants.LOCATION + `/api/User/updateGroup`, dataUpdate, {headers: AuthHeader()})
        return data
    },

    async getProgressCourse(id: number, courseId: number) {
        const {data} = await axios.get<IProgressCourseUser>(Constants.LOCATION + `/api/User/progress/${id}/${courseId}`, {headers: AuthHeader()})
        return data
    },

    async getProgressCourseForLesson(id: number, courseId: number) {
        const {data} = await axios.get<IProgressCourseUser>(Constants.LOCATION + `/api/User/progressForLesson/${id}/${courseId}`, {headers: AuthHeader()})
        return data
    },

    async updateLessonPassMark(id: number, dataUpdate: IUpdateLessonPassMark) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/User/updateLessonPassMark/${id}`, dataUpdate, {headers: AuthHeader()})
        return data
    },

    async getByPage(page: number, search?: string) {
        const {data} = await axios.get<IUserLeft>(Constants.LOCATION + `/api/User/page/${page}/${search}`, {headers: AuthHeader()})
        return data
    },

    async getQuestionsForPassingTest(testId: number, lessonId: number) {
        const {data} = await axios.get<IUserPassingTest>(Constants.LOCATION + `/api/User/questionsPassing/${testId}/${lessonId}`, {headers: AuthHeader()})
        return data
    },

    async finishTest(id: number, testId: number, lessonId: number, courseId: number, dataSubmit: IPassingTest[]) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/User/finishTest/${id}/${testId}/${lessonId}/${courseId}`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async getResultTest(id: number, testId: number, lessonId: number) {
        const {data} = await axios.get<IUserResultTest>(Constants.LOCATION + `/api/User/testResult/${id}/${testId}/${lessonId}`, {headers: AuthHeader()})
        return data
    },

    async untieCourse(dataSubmit: IUserCourseUntie) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/User/untieCourse/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async getVisits(dataSubmit: IGetVisitsUser) {
        const {data} = await axios.post<IVisitsUser[]>(Constants.LOCATION + `/api/User/getVisitsByUser/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async attachUserInCourse(dataSubmit: IUserCourse) {
        const {data} = await axios.post<IUserCourse>(Constants.LOCATION + `/api/User/attachUserInCourse/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async attachUserInCourseWithLessons(dataSubmit: ILessonsAttachInUser) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + `/api/User/attachUserInCourseWithLessons/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async downloadUps(dataSubmit: IUserCourse) {
        const {data} = await axios.post<string>(Constants.LOCATION + `/api/User/downloadUps/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async recoveryPassword(dataSubmit: IRecoveryPassword) {
        const {data} = await axios.post<IRecoveryPassword>(Constants.LOCATION + `/api/User/recoveryPassword/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async getListUsersWithoutEducationId() {
        const {data} = await axios.get<IUserWithoutEducationId[]>(Constants.LOCATION + `/api/User/getListUserWithoutEducationId/`, {headers: AuthHeader()})
        return data
    }
}