import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {ILogin} from "../../../models/login";
import {UsersService} from "../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../ui/error/ErrorMessage";
import Cookies from "universal-cookie"
import {Constants} from "../../../constans/constants";
import {useMutation} from "react-query";
import Loader from "../../ui/loader/Loader";
import {ReactComponent as EyePassword} from "../../../assets/images/global/eye-password.svg";
import {ReactComponent as Eye} from "../../../assets/images/global/eye.svg";
import Log from "../../../assets/images/global/login.png"
import useToggle from "../../../hooks/useToggle";
import ModalRecoveryPassword from "../../ui/modal/modal-recovery-password/ModalRecoveryPassword";

const Login = () => {

    const {authenticated, setAuthenticated, setUserId, setRoleId, currentRoleId} = useContext(AuthContext)
    const [error, setError] = useState<number | null>(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [role, setRole] = useState<number|null>(null)
    const [isModalRecoveryPassword, setOnModalOpenRecoveryPassword, setOnModalCloseRecoveryPassword] = useToggle()

    const navigate = useNavigate()

    const cookies = new Cookies()

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<ILogin>()

    useEffect(() => {
        if(authenticated) {
            if (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) {
                navigate("/folders")
            } else {
                navigate("/student-courses")
            }
        }
    }, []);

    const login = useMutation(
        (data: ILogin) => UsersService.login(data),
        {
            onSuccess: (data) => {
                setAuthenticated(true)
                setUserId(String(data.id))
                setRoleId(String(data.role.id))
                cookies.set("token", data.accessToken, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                cookies.set("refreshToken", data.refreshToken, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                cookies.set("refreshTokenExpiry", data.refreshTokenExpiryTime, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                cookies.set("role", data.role.id, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                cookies.set("user", data.id, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                setRole(data.role.id)
                if (Constants.LIST_ADMIN_ROLE.indexOf(data.role.id) !== -1) {
                    navigate("/folders")
                } else {
                    navigate("/student-courses")
                }
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ILogin> = async (data) => {
        data.email = data.email.trim()
        data.password = data.password.trim()
        login.mutate(data)
    }

    return (
        <>
            <div
                className="w-full h-full bg-no-repeat bg-cover"
                style={{backgroundImage: `url(${Log})`}}>
                <div
                    className="w-full h-full bg-white/50 flex flex-col gap-[12px] items-center justify-center max-md:p-[24px] text-[14px]">
                    {login.isLoading && <Loader/>}
                    <div
                        className="flex flex-col gap-[24px] justify-center px-[36px] pt-[42px] pb-[36px] rounded-[6px] bg-white max-w-[710px] w-full text-[#292B2C] max-md:px-[18px] max-md:py-[24px]">
                        <div className="flex flex-col gap-[12px]">
                            <div className="text-[28px] font-bold max-md:text-[24px]">
                                Добро пожаловать
                            </div>
                            <div className="">
                                В систему дистанционного образования, которая позволяет изучать учебные материалы,
                                проходить тестирования и отслеживать прогресс по обучению при участии куратора
                            </div>
                        </div>
                        <div className="">
                            {error &&
                                <ErrorMessage messageError={"Неправильный почта или пароль"} numberError={error}/>}
                            <form className="flex flex-col gap-[24px]" onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex gap-[14px] max-md:flex-col">
                                    <div className="w-full flex flex-col gap-[6px]">
                                        <label htmlFor="email"
                                               className="text-[14px]">
                                            Электронная почта
                                        </label>
                                        <div className="">
                                            <input
                                                {...register("email", {required: true})}
                                                type="email"
                                                autoComplete="email"
                                                className="block w-full rounded-[5px] border-[1px] border-solid border-[#BDBDBD] py-[8px] pl-[10px] pr-[12px] h-[40px]"
                                            />
                                            {errors.email && errors.email.type === "required" && (
                                                <p className="text-red-600">Заполните поле</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-[6px]">
                                        <label htmlFor="password"
                                               className="text-[14px]">
                                            Пароль
                                        </label>
                                        <div className="relative">
                                            <div onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? (
                                                    <Eye
                                                        className="w-[20px] h-[20px] absolute top-[50%] translate-y-[-50%] right-[20px] cursor-pointer"/>
                                                ) : (
                                                    <EyePassword
                                                        className="w-[20px] h-[20px] absolute top-[50%] translate-y-[-50%] right-[20px] cursor-pointer"/>
                                                )}
                                            </div>
                                            <input
                                                {...register("password", {required: true})}
                                                type={showPassword ? "text" : "password"}
                                                autoComplete="current-password"
                                                className="block w-full rounded-[5px] border-[1px] border-solid border-[#BDBDBD] py-[8px] pl-[10px] pr-[12px] h-[40px]"
                                            />
                                            {errors.password && errors.password.type === "required" && (
                                                <p className="text-red-600">Заполните поле</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="w-full rounded-[5px] bg-[#17AB57] px-[12px] py-[8px] text-[16px] font-[500] text-white"
                                >
                                    Авторизоваться
                                </button>
                            </form>
                        </div>
                        <div className="w-full justify-center flex gap-[6px] text-[14px]">
                            Забыли пароль? <span onClick={setOnModalOpenRecoveryPassword}
                                                 className="text-[#2F80ED] cursor-pointer underline">Восстановить</span>
                        </div>
                    </div>
                    <div
                        className="flex justify-center px-[36px] py-[18px] rounded-[6px] bg-white max-w-[710px] w-full max-md:px-[25px] text-center">
                        <div className=" text-[#828282] text-[14px] underline">
                            Если у вас возникли технические проблемы, свяжитесь с нами по номеру <a
                            href="tel:+7">+7XXXXX</a>
                        </div>
                    </div>
                </div>
            </div>
            {isModalRecoveryPassword && (
                <ModalRecoveryPassword
                    onClose={setOnModalCloseRecoveryPassword}
                />
            )}
        </>
    )
}

export default Login