import {Link, useParams} from "react-router-dom";
import {ReactComponent as ArrowBack} from "../../../../assets/images/global/arrow-back-icon.svg";
import React, {useContext} from "react";
import {useQuery} from "react-query";
import Cookies from "universal-cookie";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import StudentCourseListLessonsItem from "./student-course-list-lessons-item/StudentCourseListLessonsItem";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../context/AuthContext";

const StudentCourseListLessons = () => {

    const {id} = useParams()
    const cookies = new Cookies()

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const progress = useQuery(
        ["progress_user_list", currentUserId],
        () => UsersService.getProgressCourse(Number(currentUserId), Number(id)),
        {
            enabled: !!id && !!cookies.get("user"),
            onSuccess: () => {
            }
        }
    )

    if (progress.isLoading) return <div className="text-center">Загрузка...</div>

    if (progress.isError) {
        const e = progress.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!progress.data) return <div className="text-center">Ошибка данных</div>

    return (
        <div className="flex flex-col gap-[24px] h-full">
            <div className="flex justify-between items-center">
                <div className="text-[36px] leading-[120%] text-[#292B2C] font-bold">{progress.data.name}</div>
                <Link
                    to={`/student-courses`}
                    className="px-[16px] py-[12px] flex gap-[8px] items-center border-[1px] border-[#17AB57] border-solid rounded-[5px] max-lg:hidden">
                    <ArrowBack className="flex-shrink-0"/>
                    Назад
                </Link>
            </div>
            <div
                className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[24px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-0 overflow-hidden h-full">
                <div className="font-bold text-[#292B2C] text-[24px] leading-[120%]">Уроки</div>
                {/*<ScrollContainer>*/}
                    <div className="border-y-[2px] border-solid border-[#BDBDBD]">
                        {progress.data.lessons.map((lesson, index) => <StudentCourseListLessonsItem key={index} index={index} progress={progress.data} lesson={lesson}/>)}
                    </div>
                {/*</ScrollContainer>*/}
            </div>
        </div>
    )
}

export default StudentCourseListLessons