import React, {useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {ChatService} from "../../../../../services/chat.service";
import {AxiosError} from "axios";
import {ReactComponent as Send} from "../../../../../assets/images/chat/send.svg";
import {ReactComponent as FileAttach} from "../../../../../assets/images/chat/fileAttach.svg";
import {ReactComponent as Edit} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as Answer} from "../../../../../assets/images/chat/answer.svg";
import {ReactComponent as Close} from "../../../../../assets/images/global/close-modal-icon.svg";
import Cookies from "universal-cookie";
import {IActionMessage} from "../../../../../models/chat";
import Loader from "../../../../ui/loader/Loader";
import ReactQuill from "react-quill";
import {Constants} from "../../../../../constans/constants";
import {AuthContext} from "../../../../../context/AuthContext";

interface IChatSendMessage {
    chatId: number | null
    setError: (error: number) => void
    actionMessage?: IActionMessage
    setHeightActionMessage: (height: number | null) => void
    setActionMessage: (actionMessage: IActionMessage) => void
    companionId: number
    setChatScroll: (flag: boolean) => void
    refChatDetail?: React.RefObject<HTMLDivElement>
}

const ChatSendMessage = (
    {
        chatId,
        setError,
        actionMessage,
        setActionMessage,
        setHeightActionMessage,
        companionId,
        setChatScroll,
        refChatDetail
    }: IChatSendMessage
) => {

    const cookies = new Cookies()
    const queryClient = useQueryClient()

    const refMessage = useRef<ReactQuill>(null)
    const refActionMessage = useRef<HTMLDivElement>(null)
    const refUploadFile = useRef<HTMLInputElement>(null)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const [widthMessage, setWidthMessage] = useState<number | null>(null)
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    useEffect(() => {
        if (refChatDetail?.current) {
            setWidthMessage(refChatDetail.current.getBoundingClientRect().width)
        }
    }, [refChatDetail?.current]);

    const handleChangeFiles = () => {
        if (refUploadFile.current) {
            refUploadFile.current.click()
        }
    }
    const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setShowLoading(true);
            const formData = new FormData();
            formData.append('chatId', String(chatId));
            formData.append('senderId', currentUserId);
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('files', e.target.files[i]);
            }
            sendMessage.mutate(formData)
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleMessage()
        }
    };

    const handleSendMessage = () => {
        handleMessage()
    }

    const handleMessage = async () => {
        if (message.length > 0 && message !== "<p><br></p>") {
            setShowLoading(true)
            if (actionMessage && Object.keys(actionMessage).length > 0 && actionMessage.edit && !actionMessage.answer) {
                if (message.length > Constants.COUNT_SYMBOL_MESSAGE) {
                    window.confirm(`Вы превысили количество символов в сообщении на ${message.length - Constants.COUNT_SYMBOL_MESSAGE}`)
                } else {
                    const formData = new FormData();
                    formData.append('chatId', String(chatId));
                    formData.append('currentUserId', currentUserId);
                    formData.append('messageId', String(actionMessage.message.id));
                    formData.append('text', message);
                    updateTextMessage.mutateAsync(formData)
                }
            } else {
                let pattern = new RegExp(`.{1,${Constants.COUNT_SYMBOL_MESSAGE}}(\\s|$)`, 'mig')
                let splitText = message.match(pattern)
                if (splitText) {
                    const formData = new FormData();
                    formData.append('chatId', String(chatId));
                    formData.append('senderId', currentUserId);
                    if (actionMessage && Object.keys(actionMessage).length > 0 && !actionMessage.edit && actionMessage.answer) {
                        formData.append('answerMessageId', String(actionMessage.message.id));
                    }
                    for (let i = 0; i < splitText.length; i++) {
                        formData.append('messages', splitText[i]);
                    }
                    await sendMessage.mutateAsync(formData)
                }
            }
        }
    }

    const sendMessage = useMutation(
        (data: FormData) => ChatService.sendMessage(data),
        {
            onSuccess: (response) => {
                setMessage("")
                setShowLoading(false);
                closeActionMessage()
                queryClient.invalidateQueries(["count_unread_message", String(companionId)])
                // setChatScroll(true)
            },
            onError: (response) => {
                const error = response as AxiosError
                setError(error.response ? error.response.status : 400)
                setShowLoading(false);
                closeActionMessage()
            }
        }
    )

    const updateTextMessage = useMutation(
        (data: FormData) => ChatService.updateMessageText(data),
        {
            onSuccess: (response) => {
                setMessage("")
                setShowLoading(false)
                closeActionMessage()
            },
            onError: (response) => {
                const error = response as AxiosError
                setError(error.response ? error.response.status : 400)
                setShowLoading(false)
                closeActionMessage()
            }
        }
    )

    useEffect(() => {
        if (actionMessage && Object.keys(actionMessage).length > 0) {
            actionMessage.message.text = String(actionMessage.message.text)
                .replaceAll(/\r?\n|\r/g, '')
                .replaceAll('<br>', '')
                .replaceAll('<p>', '')
                .replaceAll('</p>', '')
                .replaceAll('</p><p>', '')
                .trim();
            if (actionMessage.edit && !actionMessage.answer) {
                setMessage(String(actionMessage.message.text))
                if (refActionMessage.current) {
                    setHeightActionMessage(refActionMessage.current.getBoundingClientRect().height)
                }
            } else if (!actionMessage.edit && actionMessage.answer) {
                setMessage("")
            }
        } else {
            setMessage("")
        }
    }, [actionMessage]);

    const closeActionMessage = () => {
        setHeightActionMessage(null)
        setMessage("")
        setActionMessage({} as IActionMessage)
    }

    return (
        <>
            {showLoading && <Loader/>}
            {actionMessage && Object.keys(actionMessage).length > 0 && widthMessage !== null && (
                <div ref={refActionMessage}
                     style={{width: `${widthMessage}px`}}
                     className={`bg-white px-[16px] py-[12px] flex gap-[9px] items-center`}>
                    {actionMessage.edit && !actionMessage.answer && (
                        <>
                            <Edit className="w-[32px] h-[32px] flex-shrink-0"/>
                            <div className="text-[#292B2C] w-[calc(100%-80px)]">
                                <div className="font-bold">Редактировать сообщение</div>
                                <div className="truncate"
                                     dangerouslySetInnerHTML={{__html: actionMessage.message.text ? actionMessage.message.text : ""}}/>
                            </div>
                        </>
                    )}
                    {!actionMessage.edit && actionMessage.answer && (
                        <>
                            <Answer className="w-[32px] h-[32px] flex-shrink-0"/>
                            <div
                                className="flex flex-col gap-[2px] text-[#292B2C] w-full max-lg:w-[calc(100%-74px)]">
                                <div
                                    className="font-bold">Ответ {actionMessage.message.lastName} {actionMessage.message.firstName}</div>
                                <div className="truncate"
                                     dangerouslySetInnerHTML={{__html: actionMessage.message.files ? "Вложение" : actionMessage.message.text ? actionMessage.message.text : ""}}/>
                            </div>
                        </>
                    )}
                    <Close onClick={closeActionMessage} className="w-[24px] h-[24px] flex-shrink-0 cursor-pointer"/>
                </div>
            )}
            <div style={{maxWidth: `${widthMessage}px`}} className="bg-white px-[16px] py-[12px] flex w-full gap-[10px] items-center">
                <div className="cursor-pointer">
                    <FileAttach onClick={handleChangeFiles}/>
                    <input
                        type="file"
                        name="files"
                        className="hidden"
                        accept= "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                        ref={refUploadFile}
                        onChange={e => handleUploadFiles(e)}
                        multiple/>
                </div>
                <ReactQuill
                    ref={refMessage}
                    value={message}
                    onChange={setMessage}
                    placeholder={"Сообщение"}
                    theme="snow"
                    style={{maxWidth: `calc(100% - 80px)`}}
                    className="w-full"
                    modules={Constants.REACT_QUILL_MODULES_CHAT}
                    formats={Constants.REACT_QUILL_FORMATS_CHAT}
                    onKeyDown={handleKeyPress}
                />
                <div onClick={handleSendMessage} className="py-[5.5px] pl-[7px] pr-[4px] cursor-pointer">
                    <Send/>
                </div>
            </div>
        </>
    )
}

export default ChatSendMessage